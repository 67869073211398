<template>
  <!-- Display warning if width is less than 300px -->
  <div v-if="windowWidth < 300">
    <p>
      Service Usage Notice: If the screen size is less than 300px, access to the
      service will be limited.
    </p>
    <p>
      For optimal service experience, please access using a screen wider than
      300px.
    </p>
  </div>

  <!-- Display content if width is 300px or more -->
  <div v-else>
    <!-- 1. 메뉴 설정 -->
    <MainMenu />

    <!-- 2. 스왑 부분 -->
    <div class="swap-box-container">
      <!-- Infos -->
      <!-- <InfoBox /> -->

      <SwapTitle />
      <div class="swap-container">
        <SwapBox />
        <SlippageSlider />
        <SwapDetails />
        <SwapButton />
      </div>
      <!-- TODO -->
      <!-- <a href="https://docs.opt.finance/en/event?version=latest" class="event-banner-box">
        <img class="event-banner" :src="eventBannerUrl" alt="Event Banner" />
      </a> -->
    </div>
  </div>
  <SwapFooter />
</template>

<script>
import MainMenu from "./components/MainMenu.vue";
// import InfoBox from "./components/InfoBox.vue";
import SwapBox from "./components/SwapBox.vue";
import SlippageSlider from "./components/SlippageSlider.vue";
import SwapDetails from "./components/SwapDetails.vue";
import SwapButton from "./components/SwapButton.vue";
import SwapTitle from "./components/SwapTitle.vue";
import SwapFooter from "./components/SwapFooter.vue";

export default {
  data() {
    return {
      windowWidth: 0,
    };
  },
  computed: {
    eventBannerUrl() {
      return this.windowWidth > 530
        ? require("./assets/banner.png")
        : require("./assets/banner-mobile.png");
    },
  },
  methods: {
    updateWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  },

  name: "App",
  components: {
    MainMenu,
    // InfoBox,
    SwapBox,
    SlippageSlider,
    SwapDetails,
    SwapButton,
    SwapTitle,
    SwapFooter,
  },
};
</script>

<style>
.swap-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  /* margin-top: 8px; */
  width: 100%;
  min-height: calc(100vh - 64px - 119px - 32px);
  /* margin-left: 56px; */
  /* margin-right: 56px; */
  max-width: 480px;
  /* min-width: 300px; */
}

.swap-container {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 60px;
  padding-bottom: 30px;
  margin-bottom: 20px; /* 68 */
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  max-width: 480px;
  /* min-width: 300px; */
  box-shadow: 0 2px 150px rgba(0, 0, 0, 0.15);
}

.event-banner-box {
  margin-bottom: 20px;
}

.event-banner {
  border-radius: 8px;
  width: 530px;
}

@media (max-width: 530px) {
  .swap-box-container {
    overflow-x: hidden;
  }

  .msg {
    width: 94%;
  }

  .swap-container {
    box-shadow: none;
    margin-bottom: 0px;
  }

  .event-banner {
    width: 100%;
  }

  .event-banner-box {
    margin-bottom: 30px;
  }
}

@media (max-width: 430px) {
  .swap-container {
    padding-top: 40px;
    box-shadow: none;
  }

  .msg {
    width: 92%;
  }

  .msg-box {
    margin-bottom: 20px;
  }
}
</style>
