<template>
  <div class="slider-container">
    <div class="slider-info">
      <div class="slider-title">{{ adjustSliderTitle }}</div>
      <div class="slider-data">
        <input
          class="slider-input"
          type="number"
          min="0.0"
          max="100"
          step="0.01"
          v-model="swapInfo.slippage"
          @input="handleInput($event.target.value)"
        />
        %
      </div>
    </div>
    <div class="slider-with-reset">
      <input
        class="slider"
        type="range"
        min="0.0"
        max="50"
        step="0.01"
        v-model="swapInfo.slippage"
        @input="handleInput($event.target.value)"
      />
      <button @click="resetSlider" class="reset-button">
        <img src="../assets/reset.svg" class="logo-reset" />
      </button>
    </div>
  </div>
</template>

<script>
import { getSwapInfo } from "../assets/js/webapp_context.js";
export default {
  name: "SlipageSlider",
  data() {
    return {
      swapInfo: getSwapInfo(),
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    resetSlider() {
      this.swapInfo.slippage = 1;
      window.localStorage.setItem("slippage", this.swapInfo.slippage);
    },

    handleInput(value) {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue)) {
        this.swapInfo.slippage = this.clampValue(numericValue, 0.0, 100);
        window.localStorage.setItem("slippage", this.swapInfo.slippage);
      }
    },
    clampValue(value, min, max) {
      return Math.min(Math.max(value, min), max);
    },

    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    adjustSliderTitle() {
      return this.windowWidth <= 430 ? "Slippage" : "SLIPPAGE";
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style>
.slider-container {
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.slider-info {
  /* margin-top: -5px; */
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* margin-bottom: 16px; */
}

.slider-title,
.slider-data,
.slider-input {
  /* 폰트 */
  font-size: 14px;
  /* font-family: "source-han-sans-cjk-ko", sans-serif; */
  font-weight: 500;
  font-style: normal;
}

.slider-input {
  width: 160px;
  border: none;
  text-align: right;
}

.slider-input::-webkit-inner-spin-button,
.slider-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

/* https://stackoverflow.com/questions/18389224/how-to-style-html5-range-input-to-have-different-color-before-and-after-slider */
.slider {
  accent-color: grey;
  width: 100%;
}

.slider-with-reset {
  display: flex;
  align-items: center;
}

@media (max-width: 430px) {
  .slider-with-reset {
    display: none;
  }

  .slider-container {
    margin: 0px 0px;
    margin-top: 0px;
    margin-top: 0px;
    margin-bottom: 20px;
    /* padding-bottom: 16px; */
  }

  .slider-info {
    margin: 0px 0px;
    margin-top: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .slider-title,
  .slider-data,
  .slider-input {
    padding-right: 16px;
    padding-left: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: right;
    /* 폰트 */
    font-size: 12px;
    /* font-family: "poppins", sans-serif; */
    font-weight: 400;
    font-style: normal;
  }

  .slider-input {
    width: 30px;
    padding: 0px;
    color: blue;
    text-decoration: underline;
    /* border: none; */
  }
}

.reset-button {
  margin-left: 10px;
  cursor: pointer;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
}

.logo-reset {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  transition: transform 0.5s ease;
}

.logo-reset:hover {
  transform: rotate(180deg);
}
</style>