<template>
  <div class="modal" v-if="showModal" @click="handleOutsideClick">
    <transition name="modal-content-fade">
      <div class="modal-content">
        <ul class="token-list">
          <li
            v-for="(token, index) in tokens"
            :key="token.symbol"
            @click="selectToken(index)"
          >
            <img :src="token.logo" class="token-logo" />
            <div class="token-symbol">{{ token.symbol }}</div>
            <div v-if="!isConnected" class="token-address">
              {{ token.address }}
            </div>
            <div v-else class="token-balance">{{ token.balance }}</div>
          </li>
        </ul>
        <button @click="closeModal">CLOSE</button>
      </div>
    </transition>
  </div>
</template>
  
<script>
import {
  getIsConnected,
  getTokenInfo,
} from "../assets/js/interface_request.js";
export default {
  props: {
    showModal: Boolean,
  },
  data() {
    return {
      isConnected: getIsConnected(),

      tokens: getTokenInfo(),
    };
  },
  methods: {
    selectToken(token) {
      this.$emit("select", token);
      this.closeModal();
    },
    closeModal() {
      this.$emit("close");
    },
    handleKeydown(event) {
      if (event.key === "Escape") {
        this.closeModal();
      }
    },
    handleOutsideClick(event) {
      if (event.target === event.currentTarget) {
        this.closeModal();
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>
  
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* transition: height 0.5s ease; */
}

.modal-show {
  height: 100%;
}

/* Styles for small screens */
@media (max-width: 430px) {
  .modal {
    overflow-x: hidden;
    align-items: flex-end;
    position: fixed;
    bottom: 0;
  }
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-bottom-left-radius: 46px;
  border-bottom-right-radius: 46px;
  border-top-left-radius: 46px;
  border-top-right-radius: 46px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 420px;
  /* min-width: 300px; */
  width: 90%;
  /* max-height: 95%; */
  /* overflow: auto; */
  animation: appear 0.3s ease;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0.96);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 430px) {
  .modal-content {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    align-items: flex-end;
    position: fixed;
    bottom: 0;
    animation: slideUp 0.4s ease;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.token-list {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 80%;
  max-height: 280px;
  overflow-y: auto; /* Apply scroll to the UL */
}

.token-list li {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

@media screen and (max-width: 410px) {
  .token-list li {
    justify-content: center;
  }
}

.token-list li:hover {
  background-color: #f8f8f8;
}

.token-logo {
  width: 22px;
  height: 22px;
  /* border-radius: 50%; */
  margin-right: 10px;
}

.token-symbol {
  font-weight: 700;
  margin-right: 10px;
  /* 폰트 */
  font-size: 12px;
  /* font-family: "source-han-sans-cjk-ko", sans-serif; */
  font-weight: 400;
  font-style: normal;
}

.token-address,
.token-balance {
  /* transform: translateY(2px); */
  color: rgb(97, 97, 97);
  /* 폰트 */
  font-size: 12px;
  /* font-family: "poppins", sans-serif; */
  font-weight: 400;
  font-style: normal;
  margin-left: auto;
}

@media screen and (max-width: 470px) {
  .token-address {
    font-size: 10px;
  }
}

@media screen and (max-width: 410px) {
  .token-address {
    display: none;
  }
}

button {
  /* width: 100%; */
  width: 100%;
  background-color: black;
  color: white;
  padding: 15px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 30px;
  /* 폰트 */
  font-size: 20px;
  background-color: black;
  /* font-family: "source-han-sans-cjk-ko", sans-serif; */
  font-weight: 700;
  font-style: normal;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #404040;
}
</style>
