<template>
  <footer class="footer-box-contanier">
    <div class="footer-contanier">
      <div class="social-contanier">
        <a href="https://docs.opt.finance/en/overview/?version=latest"
          ><img src="../assets/social/book.svg"
        /></a>
        <a href="https://github.com/opt-finance"
          ><img src="../assets/social/github.svg"
        /></a>
        <a href="https://t.me/OPTFinanceANN"
          ><img src="../assets/social/telegram.svg"
        /></a>
        <a href="https://twitter.com/OPTFinance"
          ><img src="../assets/social/twitter.svg"
        /></a>
        <a href="https://medium.com/@optfi">
          <img src="../assets/social/medium.svg"
        /></a>
      </div>
      <div class="copyright-contanier">
        <div class="copyright-images">
          <a href="https://wemixecofund.com/poet"
            ><img src="../assets/footer-poet.svg"
          /></a>
          <a
            href="https://skynet.certik.com/projects/opt-finance?version=latest"
            ><img src="../assets/footer-certik.svg"
          /></a>
        </div>
        <div class="copyright">© OPT DAO. All Rights Reserved.</div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "SwapTitle",
};
</script>

<style>
.footer-box-contanier {
  border-top: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  width: 80%;
  max-width: 424px;
  margin: 0 auto;
}

.footer-contanier {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.social-contanier {
  display: flex;
  opacity: 0.8;
}

.social-contanier a {
  margin-right: 12px;
}

.social-contanier img {
  width: 20px;
  height: 20px;
}

.copyright-contanier {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: grey;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
}

.copyright-images a:first-child {
  margin-right: 10px;
}

@media (max-width: 480px) {
  .social-contanier {
    display: none;
  }

  .footer {
    border-top: 1px solid #ccc;
    padding: 10px;
    text-align: center;
    width: 500px;
    margin: 0 auto;
  }

  .footer-box-contanier {
    border-top: 1px solid #ccc;
    padding: 10px;
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }

  .footer-contanier {
    justify-content: center;
  }

  .copyright-contanier {
    align-items: center;
  }
}
</style>