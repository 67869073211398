<template>
  <div class="swap-information-container">
    <div class="swap-details-default" v-if="swapInfo.defaultPath">
      DETAILS
      <div class="details-wrapper">
        <button
          class="details-info-button"
          v-on:mouseover="showDetailsTooltip"
          v-on:mouseleave="hideDetailsTooltip"
        >
          <img class="details-info-logo" src="../assets/warning.jpeg" />
        </button>
        <div v-if="isDetailsTooltipVisible" class="tooltip">
          No optimum: Use default path
        </div>
      </div>
    </div>
    <div class="swap-details" v-if="!swapInfo.defaultPath">DETAILS</div>
    <!-- 스왑 경로들 보여주기 -->
    <div class="swap-path">
      <div class="swap-title">Optimal Routes</div>
      <div class="swap-path-routes">
        <div class="path">
          <div
            v-for="(row, rowIndex) in swapImage"
            :key="rowIndex"
            class="outer-row"
          >
            <div
              v-for="(col, colIndex) in row"
              :key="colIndex"
              class="inner-row"
            >
              <img :src="col[0]" alt="Image" class="logo-path" />
              <img :src="col[1]" alt="Image" class="logo-path" />
              <div class="fee-text">{{ col[2] }}%</div>
              <img
                v-if="renderArrow(rowIndex, colIndex)"
                src="../assets/arrow-right.png"
                class="logo-arrow"
              />
            </div>
          </div>
        </div>
        <!--div class="path">
          <img src="../assets/logo/pools/stwemix-wemixd.png" class="logo-path" />
          <img src="../assets/logo/fees/25.png" class="logo-fee" />
        </div-->
      </div>
    </div>
    <!-- 유동량 차이 -->
    <!--div class="swap-path">
      <div class="swap-title">Price Impact</div>
      <div class="swap-data">0.01 %</div>
    </div-->
    <!-- TODO -->
    <!-- 수수료 미포함 가격 -->
    <!-- <div class="swap-path">
      <div class="swap-title">
        Price excluding fees
        <img
          class="wemixfi-logo"
          src="../assets/wemixfi.png"
          @mouseover="showWemixfiTooltip = true"
          @mouseout="showWemixfiTooltip = false"
        />
        <div v-if="showWemixfiTooltip" class="custom-tooltip">
          WemixFi displays this value
        </div>
      </div>
      <div class="swap-data">
        0
        {{ tokens[swapInfo.tokenOut].symbol }}
      </div>
    </div> -->
    <!-- 슬리피지 포함된 받을 양 -->
    <div class="minimum-receive">
      <div class="swap-title">Minimum Received</div>
      <div class="swap-data">
        {{ formattedMinimumReceived }}
        {{ tokens[swapInfo.tokenOut].symbol }}
      </div>
    </div>
    <!-- 가격 차이 -->
    <div
      class="savings"
      v-if="
        formattedSavings != 0 && formattedSavings != '' && !swapInfo.defaultPath
      "
    >
      <div class="swap-title">
        Savings
        <div class="savings-wrapper">
          <button
            class="savings-info-button"
            v-on:mouseover="showSavingsTooltip"
            v-on:mouseleave="hideSavingsTooltip"
          >
            <img class="savings-info-logo" src="../assets/info-tooltip.svg" />
          </button>
          <div v-if="isSavingsTooltipVisible" class="tooltip">
            Compared to a single path
          </div>
        </div>
      </div>
      <div class="savings-data-box">
        <div class="savings-data">
          {{ "+" }}
          {{ formattedSavings }}
          {{ tokens[swapInfo.tokenOut].symbol }}
        </div>
      </div>
    </div>
    <!-- <div class="swap-path">
      <div class="swap-title">Price Impact</div>
      <div class="swap-data">0 %</div>
    </div> -->
    <!-- 가스 예상량 -->
    <div v-if="swapInfo.gas != ''" class="gas-estimate">
      <div class="swap-title">Estimated Gas</div>
      <div class="swap-data">{{ formattedEstimatedGas }} WEMIX</div>
    </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import {
  getTokenList,
  getSwapInfo,
  getSwapImage,
  getDecimals,
} from "../assets/js/webapp_context.js";
export default {
  name: "SwapDetails",
  data() {
    return {
      isSavingsTooltipVisible: false,
      isDetailsTooltipVisible: false,
      // showWemixfiTooltip: false,
      routes: ["WEMIX->WEMIX$", "WEMIX->RFT->WEMIX$"],
      swapInfo: getSwapInfo(),
      swapImage: getSwapImage(),
      tokens: getTokenList(),
    };
  },
  methods: {
    sleep(ms) {
      return new Promise((r) => setTimeout(r, ms));
    },
    showSavingsTooltip: async function () {
      this.isSavingsTooltipVisible = true;
      await this.sleep(3000);
      this.hideSavingsTooltip();
    },
    hideSavingsTooltip() {
      this.isSavingsTooltipVisible = false;
    },
    showDetailsTooltip: async function () {
      this.isDetailsTooltipVisible = true;
      await this.sleep(3000);
      this.hideDetailsTooltip();
    },
    hideDetailsTooltip() {
      this.isDetailsTooltipVisible = false;
    },
  },
  computed: {
    formattedMinimumReceived: {
      get() {
        try {
          const value = ethers.formatUnits(
            this.swapInfo.amountOut,
            getDecimals(this.swapInfo.tokenOut)
          );
          return ((value * (100 - this.swapInfo.slippage)) / 100).toFixed(
            getDecimals(this.swapInfo.tokenOut)
          );
        } catch (error) {
          return 0;
        }
      },
    },
    formattedSavings: {
      get() {
        // console.log("savings:", this.swapInfo.savings);
        if (this.swapInfo.savings === "") {
          return "";
        }
        return ethers.formatUnits(
          this.swapInfo.savings,
          getDecimals(this.swapInfo.tokenOut)
        );
      },
    },
    formattedEstimatedGas: {
      get() {
        // try {
        return ethers.formatUnits(this.swapInfo.gas, 9 - 2); // * price (100)
        // } catch (error) {
        // console.error(error);
        // return "";
        // }
      },
    },

    renderArrow() {
      // This function checks the condition for rendering span elements
      return (row, col) => {
        if (this.swapImage && this.swapImage.length > row)
          return col < this.swapImage[row].length - 1;
        else return false;
      };
    },
  },
};
</script>

<style scoped>
.swap-information-container {
  /* transform: translateY(-152px); */
  margin: 6px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 15px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 16px;
}

/* @media (max-width: 430px) {
  .swap-information-container {
    margin-bottom: 16px;
  }
} */

.swap-details,
.swap-details-default {
  display: flex;
  /* flex-direction: column; */
  align-items: center;

  /* 마진 설정 */
  margin-bottom: 18px;
  /* 아래 줄 긋기 */
  border-bottom: 1px solid #ddd;
  padding-bottom: 12px;
  /* 폰트 */
  font-size: 14px;
  /* font-family: "source-han-sans-cjk-ko", sans-serif; */
  font-weight: 400;
  font-style: normal;

  display: flex;
}

.swap-path {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 8px;
  /* 폰트 */
  font-size: 12px;
  /* font-family: "poppins", sans-serif; */
  font-weight: 400;
  font-style: normal;
  text-align: right;
}

.path {
  text-align: right;
}

@media (max-width: 400px) {
  .swap-path {
    display: none;
  }
}

.minimum-receive {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 8px;
  /* 폰트 */
  font-size: 12px;
  /* font-family: "poppins", sans-serif; */
  font-weight: 400;
  font-style: normal;
}

.savings {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 8px;
  /* 폰트 */
  font-size: 12px;
  /* font-family: "poppins", sans-serif; */
  font-weight: 400;
  font-style: normal;
}

.savings-data-box {
  display: flex;
  justify-content: right;
  align-items: flex-end;
  /* border-radius: 4px; */
  /* padding-right: 8px; */
  /* padding-left: 8px; */
  /* background-color: black; */
}

.savings-data {
  /* font-size: 10px; */
  color: rgb(0, 160, 0);
  text-align: right;
}

.savings-info-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding-right: 4px;
  padding-left: 4px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.savings-info-logo {
  width: 14px;
}

.savings-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
}

.gas-estimate {
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* 폰트 */
  font-size: 12px;
  /* font-family: "poppins", sans-serif; */
  font-weight: 400;
  font-style: normal;
}

.swap-data {
  /* padding-top: 4px; */
  color: grey;
  text-align: right;
}

.swap-title,
.savings-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wemixfi-logo {
  margin-left: 4px;
  height: 12px;
}

.swap-path-routes {
  color: grey;
  display: flex;
  flex-direction: column;
  text-align: right;
}

.inner-row {
  padding-bottom: 5px;
  display: inline-block;
}

.logo-path-div {
  display: inline-block;
}

.logo-path {
  height: 18px;
  vertical-align: middle;
}

.fee-text {
  display: inline-block;
  padding-left: 2px;
  position: relative;
  top: 2px;
}

.logo-arrow {
  vertical-align: middle;
  width: 8px;
  height: 8px;
  margin-left: 5px;
  margin-right: 5px;
}

.logo-fee {
  margin-left: 4px;
  width: 42px;
}

.details-info-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding-right: 4px;
  padding-left: 4px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.details-info-logo {
  width: 12px;
}

.details-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
}

.tooltip {
  position: absolute;
  /* transform: translatex(116px) translatey(-6px); */
  transform: translatey(-30px);
  padding: 10px;
  /* border: 1px solid grey; */
  background: black;
  border-radius: 8px;
  color: white;
  white-space: pre-line;
  box-shadow: 0 2px 150px rgb(0, 0, 0, 0.15);
  /* 폰트 */
  font-size: 12px;
  /* font-family: "poppins", sans-serif; */
  font-weight: 400;
  font-style: normal;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: -6px; /* Half of the arrow's height */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black; /* Same color as the text bubble */
}

/*
@media (max-width: 530px) {
  .details-wrapper,
  .savings-wrapper {
    display: none;
  }
}
*/
</style>
