<template>
  <div class="swap-button-container">
    <button v-if="!isConnected" class="swap-button swap-button-disabled">
      SWAP
    </button>
    <button
      v-else-if="!isApproved"
      class="swap-button"
      @click="sendApproveMax()"
    >
      <div v-if="connecting" class="swap-button-spinner-container">
        <div class="swap-button-spinner"></div>
      </div>
      APPROVE
    </button>
    <button
      v-else-if="swapInfo.amountIn <= swapInfo.balanceIn && swapInfo.gas !== ''"
      class="swap-button"
      @click="sendSwap()"
    >
      <div v-if="connecting" class="swap-button-spinner-container">
        <div class="swap-button-spinner"></div>
      </div>
      SWAP
    </button>
    <button v-else class="swap-button swap-button-disabled">SWAP</button>
  </div>
</template>

<script>
import { getIsConnected } from "../assets/js/interface_request.js";
import {
  getIsApproved,
  getSwapInfo,
  approveMax,
  updateSwapOutAmount,
  swap,
} from "../assets/js/webapp_context.js";
export default {
  name: "SwapButton",
  data() {
    return {
      connecting: false,

      isConnected: getIsConnected(),
      isApproved: getIsApproved(),
      swapInfo: getSwapInfo(),
    };
  },
  methods: {
    sendApproveMax: async function () {
      this.connecting = true;
      await approveMax();
      await updateSwapOutAmount(true);
      this.connecting = false;
    },
    sendSwap: async function () {
      this.connecting = true;
      await swap();
      this.connecting = false;
    },
  },
};
</script>

<style>
.swap-button-container {
  display: flex;
  align-items: center;
  width: 100%;
  /* height: 44px; */
  margin-top: 20px;
}

.swap-button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px 10px;
  height: 56px;
  background-color: black;
  /* 버튼 배경색 */
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s;
  /* 중앙 정렬 */
  /* margin: 0 auto; */
  /* max-width: 550px; */
  width: 100%;
  /* 폰트 */
  font-size: 20px;
  background-color: black;
  /* font-family: "source-han-sans-cjk-ko", sans-serif; */
  font-weight: 700;
  font-style: normal;
}

@media (max-width: 530px) {
  .swap-button {
    border-radius: 15px;
  }
}

.swap-button-disabled {
  cursor: default;
  background-color: grey;
}

/* 버튼 hover 효과 */
.swap-button:hover {
  background-color: #404040;
}

.swap-button-disabled:hover {
  background-color: grey;
}

.swap-button-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 16px; */
  /* height: 16px; */
  margin-right: 12px;
}
.swap-button-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border-left-color: grey;
  animation: spin 1s ease infinite;
}
</style>