<template>
  <div class="modal" v-if="showModal" @click="handleOutsideClick">
    <transition name="modal-content-fade">
      <div class="modal-content">
        <div class="personalized-title">Set My Color</div>
        <div class="personalized-color-content">
          <v-swatches
            class="personalized-color"
            v-model="selectedColor"
            :swatches="formattedCustomColors"
            :swatch-style="{ borderRadius: '100px' }"
            inline
          />
        </div>
        <div class="personalized-text-last">
          Personalize your own color<br/>
          and use it to distinguish phishing sites.
        </div>
        <button class="color-select-botton" @click="closeModal">CLOSE</button>
      </div>
    </transition>
  </div>
</template>
  
<script>
import { VSwatches } from "vue3-swatches";
import "vue3-swatches/dist/style.css";

export default {
  components: {
    VSwatches,
  },
  props: {
    showModal: Boolean,
  },
  data() {
    return {
      windowWidth: window.innerWidth,

      selectedColor: window.localStorage.getItem("color") || "#000000",
      customColors7: [
        "#CC3333",
        "#FFCC00",
        "#66CC33",
        "#33CCFF",
        "#336699",
        "#666699",
        "#000000",
      ],
      customColors5: ["#CC3333", "#FFCC00", "#66CC33", "#33CCFF", "#000000"],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    handleKeydown(event) {
      if (event.key === "Escape") {
        this.closeModal();
      }
    },
    handleOutsideClick(event) {
      if (event.target === event.currentTarget) {
        this.closeModal();
      }
    },

    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  watch: {
    selectedColor(newValue, oldValue) {
      oldValue;
      // console.log(`Color changed from ${oldValue} to ${newValue}`);
      window.localStorage.setItem("color", newValue);
      this.$emit("select", newValue);
      this.closeModal();
    },
  },
  computed: {
    formattedCustomColors() {
      // console.log(this.windowWidth);
      if (this.windowWidth < 400) {
        return this.customColors5;
      } else {
        return this.customColors7;
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeydown);
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeydown);
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-show {
  height: 100%;
}

/* Styles for small screens */
@media (max-width: 430px) {
  .modal {
    overflow-x: hidden;
    align-items: flex-end;
    position: fixed;
    bottom: 0;
  }
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-bottom-left-radius: 46px;
  border-bottom-right-radius: 46px;
  border-top-left-radius: 46px;
  border-top-right-radius: 46px;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.2);
  max-width: 420px;
  /* min-width: 300px; */
  width: 90%;
  /* max-height: 95%; */
  /* overflow: auto; */
  animation: appear 0.3s ease;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0.96);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 430px) {
  .modal-content {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    align-items: flex-end;
    position: fixed;
    bottom: 0;
    animation: slideUp 0.4s ease;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.color-select-botton {
  /* width: 100%; */
  width: 100%;
  background-color: black;
  color: white;
  padding: 15px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  /* 폰트 */
  font-size: 20px;
  background-color: black;
  font-weight: 700;
  font-style: normal;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #404040;
}

/* .custom-ui-class {
  margin-left: auto;
  margin-right: auto;
} */

.personalized-color {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 20px;
}

.personalized-color-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.personalized-text,
.personalized-text-last {
  text-align: center;

  color: rgb(22, 22, 22);
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
}

.personalized-text {
  margin-bottom: 3px;
}

.personalized-text-last {
  margin-bottom: 20px;
}

.personalized-title {
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  font-size: 20px;
  font-style: normal;

  margin-bottom: 20px;
}
</style>
