import { ref } from 'vue';
import { ethers } from "ethers";
// import EthereumProvider from "@walletconnect/ethereum-provider";
import UniversalProvider from "@walletconnect/universal-provider";

import ADDRESS from '../json/address.json';
import TOKEN from '../json/tokens.json';
import AGG from '../json/AggregatorUpgradeable.json';
import AGGVIEW from '../json/AggregatorViewUpgradeable.json';
import MULTICALL from '../json/MultiCall.json';
import IERC20 from '../json/IERC20.json';

let walletKind = ref("");
let isConnected = ref(false);
let provider = undefined;
let address = ref(undefined);

let contract_tokens = Array(TOKEN.tokens.length);
let contract_agg = undefined;
let contract_aggView = undefined;
let contract_multicall = undefined;

let tokenInfos = ref(TOKEN.tokens);

function getWalletKind() {
    return walletKind;
}

// TODO: ENV
const projectId = "0c06b9b2105f1d8fcced34f829538260";

function getProvider() {
    return provider;
}

async function setProvider(_provider = undefined) {
    // provider = await EthereumProvider.init({
    //     projectId: projectId,
    //     chains: [1111],
    //     methods: [
    //         "eth_sendTransaction",
    //         "eth_signTransaction",
    //         "eth_sign",
    //         "eth_getBalance",
    //         "eth_signTypedData",
    //         "eth_call",
    //         "personal_sign",
    //         "wallet_watchAsset",
    //         "wallet_addEthereumChain",
    //         "wallet_switchEthereumChain",
    //     ],
    //     showQrModal: true,
    //     qrModalOptions: {
    //         themeMode: "light",
    //     },
    // });
    if (_provider) {
        provider = _provider;
    } else {
        provider = await UniversalProvider.init({
            projectId: projectId,
            logger: "debug",
            relayUrl: "wss://relay.walletconnect.com",
            metadata: {
                name: "OPT SWAP",
                description: "OPT SWAP :: #1 DEX Aggregator on WEMIX.",
                url: "https://swap.opt.finance/",
                icons: ["https://swap.opt.finance/img/logo.65c652c6.png"],
            },
        });
    }
}

function resetProvider() {
    provider = undefined;
}

function getAddress() {
    return address;
}

async function connectContract(provider) {
    // try {
    contract_agg = new ethers.Contract(ADDRESS.agg, AGG.abi, await provider);
    contract_aggView = new ethers.Contract(ADDRESS.aggView, AGGVIEW.abi, await provider);
    for (let i = 0; i < TOKEN.tokens.length; i++) {
        contract_tokens[i] = new ethers.Contract(TOKEN.tokens[i].address, IERC20.abi, await provider);
    }
    contract_multicall = new ethers.Contract(ADDRESS.multicall, MULTICALL.abi, await provider);
    // } catch (error) {
    // console.error(error);
    // throw error;
    // }
}

async function getBalanceOf(token) {
    if (!isConnected.value)
        return

    const provider = getProvider();

    let balance;
    if (token === 0) {
        balance = await provider.getBalance(address.value);
    } else {
        balance = await contract_tokens[token].balanceOf(address.value);
    }

    return balance;
}

function getTokenInfo() {
    return tokenInfos;
}

async function getBalancesOf() {
    if (!isConnected.value) {
        return;
    }

    const provider = getProvider();

    let balances = [];
    balances.push(
        `${await provider.getBalance(address.value)}`
    ); // token0

    const funcsig = ethers.id('balanceOf(address)').substring(0, 10);
    let calls = [];

    for (let token = 1; token < contract_tokens.length; token++) {
        // let balance;
        // balance = await contract_tokens[token].balanceOf(address.value);
        // balances.push(balance.toString());
        calls.push({
            target: (await contract_tokens[token].getAddress()),
            callData: funcsig + (ethers.AbiCoder.defaultAbiCoder().encode(['address'], [address.value])).substring(2)
        });
    }
    // try {
    const res = await contract_multicall.tryAggregate.staticCall(false, calls);

    for (let token = 0; token < calls.length; token++) {
        const r = res[token];
        if (r[0]) {
            balances.push(`${ethers.formatUnits(r[1], 0)}`);
        } else {
            balances.push("0");
        }
    }
    // } catch (error) {
    // console.error(error);
    // throw error;
    // }

    if (balances) {
        tokenInfos.value = TOKEN.tokens.map((elem, index) => {
            return { ...elem, "balance": `${ethers.formatUnits(balances[index], TOKEN.tokens[index].decimals)} ${TOKEN.tokens[index].symbol}` }
        });
    } else {
        tokenInfos.value = TOKEN.tokens;
    }

    return balances;
}

function getIsConnected() {
    return isConnected;
}

function getAggContract() {
    return contract_agg;
}

function getAggViewContract() {
    return contract_aggView;
}

function getTokenContract(token) {
    if (token < 0 || token >= contract_tokens.length)
        return undefined;
    return contract_tokens[token];
}

export {
    contract_agg,
    contract_aggView,
}
export {
    getWalletKind,
    getProvider,
    setProvider,
    resetProvider,
    getAddress,
    connectContract,
    getTokenInfo,
    getBalanceOf,
    getBalancesOf,
    getIsConnected,
    getAggContract,
    getAggViewContract,
    getTokenContract
};