<template>
  <!-- 바꾸는 토큰 -->
  <TokenSelectModal
    :showModal="showInModal"
    @select="(token) => this.resetSwapInfo(token, swapInfo.tokenOut)"
    @close="showInModal = false"
  />
  <TokenSelectModal
    :showModal="showOutModal"
    @select="(token) => this.resetSwapInfo(swapInfo.tokenIn, token)"
    @close="showOutModal = false"
  />
  <!-- 파는 토큰 -->
  <div class="swap-input-from">
    <div class="token-from">
      <label class="token-from-label">YOU SELL</label>
    </div>
    <div class="currency-input">
      <div class="percentage-buttons-from">
        <button class="hide-on-small" @click="updateSwapAmount(1, 4)">
          25%
        </button>
        <button class="hide-on-small" @click="updateSwapAmount(1, 2)">
          50%
        </button>
        <button class="hide-on-small" @click="updateSwapAmount(3, 4)">
          75%
        </button>
        <button class="hide-on-small" @click="updateSwapAmount(1, 1)">
          MAX
        </button>
      </div>
      <div class="input-and-token-select">
        <button class="token-select-button" @click="showInModal = true">
          <div class="token-logo-symbol">
            <img :src="tokens[swapInfo.tokenIn].logo" class="logo-token" />
            <div class="token-name">{{ tokens[swapInfo.tokenIn].symbol }}</div>
          </div>
          <img src="../assets/arrow-down.png" class="logo-arrow-down" />
        </button>
        <div class="fix-height">
          <input
            class="token-select-input"
            type="number"
            placeholder="0.0"
            min="0"
            v-model="formattedAmountIn"
            :readonly="!isLoaded"
            @input="debouncedInput"
          />
        </div>
      </div>
      <div class="currency-balance">Balance: {{ formattedBalanceIn }}</div>
    </div>
  </div>

  <!-- 화살표 -->
  <div class="arrow-container">
    <button class="arrow-button" @click="toggleToken()">
      <img src="../assets/arrow.svg" class="logo-arrow" />
    </button>
  </div>

  <!-- 받는 토큰 -->
  <div class="swap-input-to">
    <div class="token-to">
      <label class="token-to-label">YOU BUY</label>
    </div>
    <div class="currency-input">
      <div class="percentage-buttons-to"></div>
      <div class="input-and-token-select">
        <button class="token-select-button" @click="showOutModal = true">
          <div class="token-logo-symbol">
            <img :src="tokens[swapInfo.tokenOut].logo" class="logo-token" />
            <div class="token-name">{{ tokens[swapInfo.tokenOut].symbol }}</div>
          </div>
          <img src="../assets/arrow-down.png" class="logo-arrow-down" />
        </button>
        <div class="fix-height">
          <input
            v-if="isLoaded"
            class="token-select-input"
            type="number"
            placeholder="0.0"
            min="0"
            v-model="formattedAmountOut"
            readonly
          />
          <div v-if="!isLoaded" class="spinner-container">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
      <div class="currency-balance">Balance: {{ formattedBalanceOut }}</div>
    </div>
  </div>

  <!-- 교환비 -->
  <div class="swap-ratio-box">
    <div class="swap-ratio-price">Price</div>
    <div class="swap-ratio">
      1 {{ tokens[swapInfo.tokenIn].symbol }} = {{ exchangeRatio }}
      {{ tokens[swapInfo.tokenOut].symbol }}
    </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import TokenSelectModal from "./TokenSelectModal.vue";
import {
  getTokenList,
  getSwapInfo,
  resetSwapInfo,
  setSwapAmount,
  getIsLoaded,
  getDecimals,
  updateSwapOutAmount,
  debounce,
} from "../assets/js/webapp_context.js";
// import { getIsConnected } from "../assets/js/interface_request.js";
export default {
  name: "SwapBox",
  components: {
    TokenSelectModal,
  },
  data() {
    return {
      showInModal: false,
      showOutModal: false,
      swapInfo: getSwapInfo(),
      tokens: getTokenList(),
      isLoaded: getIsLoaded(),
      // isConnected: getIsConnected(),

      debouncedInput: debounce(this.validateInput, 500), // 500ms delay
    };
  },
  methods: {
    resetSwapInfo(tokenIn, tokenOut) {
      // Toggle
      // TODO: arbitrage profit
      if (
        tokenIn === this.swapInfo.tokenOut ||
        tokenOut === this.swapInfo.tokenIn
      ) {
        this.toggleToken();
        return;
      }

      resetSwapInfo(tokenIn, tokenOut);
      this.swapInfo.amountIn = "";
    },

    updateSwapAmount(nom, denom) {
      if (
        this.swapInfo.balanceIn == 0 ||
        this.swapInfo.balanceIn == "" ||
        !this.swapInfo.balanceIn
      ) {
        return;
      }

      let value;
      if (nom === 1 && denom === 1) {
        value = ethers.toBigInt(this.swapInfo.balanceIn);
        if (this.swapInfo.tokenIn === 0) {
          value -= ethers.parseUnits("1", 18 - 1); // for gas fee
        }
      } else {
        value =
          (ethers.toBigInt(this.swapInfo.balanceIn) * ethers.toBigInt(nom)) /
          ethers.toBigInt(denom);
      }

      if (this.swapInfo.balanceIn != "") {
        setSwapAmount(value);
      }

      updateSwapOutAmount(false);
    },

    validateInput(event) {
      if (event.target.value === "") {
        this.swapInfo.amountIn = "";
        return;
      }

      // has decimal issue
      // const value = parseFloat(event.target.value);
      // let valueStr = value.toString();
      let valueStr = event.target.value;

      const regex = new RegExp(
        `^\\d*\\.?\\d{0,${getDecimals(this.swapInfo.tokenIn)}}$`
      );
      // console.log(regex.test(valueStr));
      if (regex.test(valueStr)) {
        setSwapAmount(
          ethers.parseUnits(valueStr, getDecimals(this.swapInfo.tokenIn))
        );
      }

      updateSwapOutAmount(false);
    },

    async toggleToken() {
      await resetSwapInfo(this.swapInfo.tokenOut, this.swapInfo.tokenIn);
      this.swapInfo.amountIn = "";
    },
  },
  computed: {
    formattedAmountIn: {
      get() {
        if (this.swapInfo.amountIn === "" || this.swapInfo.amountIn === 0) {
          return "";
        } else if (this.swapInfo.amountIn !== "") {
          let preAmountIn = ethers.formatUnits(
            this.swapInfo.amountIn.toString(),
            getDecimals(this.swapInfo.tokenIn)
          );
          if (
            Number.isInteger(preAmountIn) ||
            preAmountIn - Math.floor(preAmountIn) === 0
          ) {
            preAmountIn = Math.floor(preAmountIn);
          }
          return preAmountIn;
        } else {
          return "";
        }
      },
    },
    formattedAmountOut: {
      get() {
        if (
          this.swapInfo.amountOut === "" ||
          this.swapInfo.amountIn === "" ||
          this.swapInfo.amountIn === 0
        ) {
          return "";
        }
        return ethers.formatUnits(
          this.swapInfo.amountOut.toString(),
          getDecimals(this.swapInfo.tokenOut)
        );
      },
    },

    formattedBalanceIn: {
      get() {
        try {
          return ethers.formatUnits(
            this.swapInfo.balanceIn.toString(),
            getDecimals(this.swapInfo.tokenIn)
          );
        } catch (error) {
          return "";
        }
      },
    },
    formattedBalanceOut: {
      get() {
        try {
          return ethers.formatUnits(
            this.swapInfo.balanceOut.toString(),
            getDecimals(this.swapInfo.tokenOut)
          );
        } catch (error) {
          return "";
        }
      },
    },

    exchangeRatio: {
      get() {
        try {
          let swapInfoAmountIn = this.swapInfo.amountIn;
          let swapInfoAmountOut = this.swapInfo.amountOut;
          if (
            swapInfoAmountIn === "" ||
            swapInfoAmountIn === 0 ||
            swapInfoAmountOut === ""
          ) {
            throw new Error("Cannot divided with zero or empty.");
          }
          return (
            ethers.formatUnits(
              swapInfoAmountOut.toString(),
              getDecimals(this.swapInfo.tokenOut)
            ) /
            ethers.formatUnits(
              swapInfoAmountIn.toString(),
              getDecimals(this.swapInfo.tokenIn)
            )
          ).toFixed(18);
        } catch (error) {
          return "?";
        }
      },
    },
  },
};
</script>

<style>
.swap-input-from {
  width: 100%;
  position: relative;
}

.swap-input-to {
  position: relative;
}

.currency-input {
  display: flex;
  flex-direction: column;

  /* width: 99%; */
  height: 94px;
  /* padding: 8px; */
  padding-top: 10px;
  padding-left: 6px;
  padding-right: 6px;
  border: 2px solid black;
  border-radius: 18px;
  background-color: white;
  position: relative;
  z-index: 1;
}

@media (max-width: 430px) {
  .currency-input {
    justify-content: space-between;
    padding-top: 6px;
    padding-bottom: 8px;
  }
}

.token-from {
  width: 114px;
  height: 100px;
  position: absolute;
  top: -30px;
  background-color: black;
  color: white;
  border-radius: 18px;
  padding-top: 4px;
}

.token-to {
  width: 114px;
  height: 100px;
  position: absolute;
  top: -30px;
  background-color: black;
  color: white;
  border-radius: 18px;
  padding-top: 4px;
}

.input-and-token-select {
  display: flex;
  align-items: center;
}

@media (max-width: 430px) {
  .input-and-token-select {
    /* display: flex; */
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
  }
}

.token-select-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid black;
  background-color: transparent;
  border-radius: 24px;
  cursor: pointer;
}

@media (max-width: 430px) {
  .token-select-button {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
}

.token-logo-symbol {
  display: flex;
  align-items: center;
  width: 100px;
}

.token-name {
  width: 60px;
  /* 폰트 */
  font-size: 12px;
  /* font-family: "source-han-sans-cjk-ko", sans-serif; */
  font-weight: 400;
  font-style: normal;
}

.logo-token {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 14px;
  width: 26px;
}

.logo-arrow-down {
  margin-left: 26px;
  margin-right: 10px;
  width: 16px;
}

.token-select-input {
  text-align: right;
  width: 100%;
  background-color: transparent;
  border: none;
  /* margin-left: 12px; */
  /* margin-right: 12px; */
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;
  margin-right: 10px;

  color: black;

  /* 폰트 */
  font-size: 23px;
  /* font-family: "source-han-sans-cjk-ko", sans-serif; */
  font-weight: 400;
  font-style: normal;
}

@media (max-width: 430px) {
  .token-select-input {
    /* width: 93%; */
    padding-top: 4px;
  }
}

.token-select-input:focus {
  outline: none;
}

.token-select-input::-webkit-inner-spin-button,
.token-select-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.percentage-buttons-from {
  align-self: flex-end;
}

@media (max-width: 430px) {
  .percentage-buttons-from .hide-on-small {
    display: none;
  }
}

.percentage-buttons-from button {
  width: 44px;
  height: 20px;

  margin-right: 6px;
  /* padding-top: 4px; */
  /* transform: translateY(-4px); */

  cursor: pointer;

  background-color: rgb(97, 97, 97);
  border-radius: 5px;
  border: none;

  color: white;
  /* 폰트 */
  font-size: 10px;
  /* font-family: "poppins", sans-serif; */
  font-weight: 400;
  font-style: normal;
}

.percentage-buttons-from button:last-child {
  margin-right: 12px;
}

.percentage-buttons-to {
  height: 22px;
}

.currency-balance {
  text-align: right;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 10px;
  margin-bottom: 2px;
  transform: translateY(-6px);

  color: rgb(97, 97, 97);

  /* 폰트 */
  font-size: 12px;
  /* font-family: "poppins", sans-serif; */
  font-weight: 400;
  font-style: normal;
}

.token-from-label,
.token-to-label {
  padding: 4px 0px 0px 0px;
  /* 폰트 */
  font-size: 14px;
  text-align: center;
  /* font-family: "source-han-sans-cjk-ko", sans-serif; */
  font-weight: 500;
  font-style: normal;
}

.swap-input-from label,
.swap-input-to label {
  margin-bottom: 4px;
  display: block;
}

.arrow-container {
  transform: translateY(1px);
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  z-index: 2;
}

.arrow-button {
  cursor: pointer;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
}

.logo-arrow {
  transition: transform 0.5s ease;
}

.logo-arrow:hover {
  transform: rotate(180deg);
}

.logo-arrow {
  width: 32px;
  height: 32px;
}

.swap-ratio {
  margin-left: auto;
  text-align: right;
  /* 폰트 */
  font-size: 12px;
  /* font-family: "poppins", sans-serif; */
  font-weight: 400;
  font-style: normal;
}

.swap-ratio-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0px 16px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.swap-ratio-price {
  display: none;
  font-size: 12px;
}

@media (max-width: 430px) {
  .swap-ratio-box {
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
  }
  .swap-ratio-price {
    display: flex;
  }
}

.spinner-container {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  margin-right: 12px;
  padding-top: 8px;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border-left-color: grey;
  animation: spin 1s ease infinite;
}

.fix-height {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align items to the right */
}

@media (max-width: 430px) {
  .spinner-container {
  }
  .fix-height {
    height: 36px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>