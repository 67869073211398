import { ref } from 'vue';
import { ethers } from "ethers";

import TOKEN from '../json/tokens.json'
import ADDRESS from '../json/address.json';
import POOL from '../json/pools.json';

import {
    // getWalletKind,
    getProvider,
    getAddress,
    getIsConnected,
    // getBalanceOf,
    getBalancesOf,
    getAggContract,
    getAggViewContract,
    getTokenContract
} from './interface_request.js'
import {
    multiPathSwapOut_contract,
    approve_contract,
    multiPathSwapOutAmount_contract,
    // multiPathSwapOut_static_contract,
    allowance_contract,
    multiPathSwapOut_estimate_contract,
    v2OnlySwap_contract,
    v2SingleSwap_estimate_contract,
    getAmountsOut_contract,
    // v3OnlySingleSwap_contract,
    // v3OnlySwap_contract
} from './contract_request.js'

let swapInfo = ref({
    "tokenIn": 0,
    "tokenOut": 1,
    "amountIn": '',
    "amountOut": '',
    "balanceIn": '',
    "balanceOut": '',
    "slippage": window.localStorage.getItem("slippage") || 1,
    "path": [],
    // "price impact" // TODO
    "savings": '',
    "gas": '',
    "defaultPath": false,
});
let swapImage = ref([[], [], []]);
let isApproved = ref(false);
let isLoaded = ref(true);

const WWEMIX = "0x7D72b22a74A216Af4a002a1095C8C707d6eC1C5f";
const DEFAULT_POOL_ICON = "https://wemixscan.com/images/main/empty-token.png";
import DEFAULT_POOL_TOKENS from '../json/defaultPaths_tokens.json';
import DEFAULT_POOL_POOL from '../json/defaultPaths_pool.json';

function getTokenList() {
    return TOKEN.tokens;
}

function getDecimals(tokenIndex) {
    return TOKEN.tokens[tokenIndex].decimals;
}

function getSwapInfo() {
    return swapInfo;
}

function getSwapImage() {
    return swapImage;
}

function getIsApproved() {
    return isApproved;
}

function getIsLoaded() {
    return isLoaded;
}

async function _getPath(fromToken, toToken, amountIn) {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 6000);

    let data;
    try {
        const uri = `https://api.opt.finance/path?from=${fromToken}&to=${toToken}&in=${amountIn}`;
        // console.log(uri);

        const response = await fetch(uri, { signal: controller.signal });
        data = await response.json();
        // console.log(data);

        if (data.result === "found") {
            swapInfo.value.savings = data.saving || '';
            swapInfo.value.defaultPath = false;
            return data;
        } else {
            throw new Error('Not a vaild result.');
        }
    } catch (error) {
        swapInfo.value.savings = '';
        swapInfo.value.defaultPath = true;
        return;
        // console.error('Error fetching data:', error);
        // throw error;
    } finally {
        clearTimeout(timeoutId);
    }
}

async function approveMax() {
    if (!getIsConnected().value)
        return;

    const tokenContract = getTokenContract(swapInfo.value.tokenIn);
    try {
        await approve_contract(tokenContract, ADDRESS.agg, ethers.MaxUint256);
        // console.log("response", response);
    } catch (error) {
        // console.error(error);
        // throw error;
    }

    isApproved.value = await _getSufficientlyApproved();
}

// function encodeV3Path(pathv3) {
//     const types = ["address"];
//     for (let i = 1; i < pathv3.length; i += 2) {
//         types.push("uint24", "address");
//     }

//     const encodedV3Path = ethers.solidityPacked(types, pathv3);
//     return encodedV3Path;
// }

async function swap() {
    if (!getIsConnected().value)
        return;

    const provider = getProvider();

    const address = getAddress().value;

    const contract = getAggContract();
    const contractView = getAggViewContract();

    let fromToken = TOKEN.tokens[swapInfo.value.tokenIn].address;
    let toToken = TOKEN.tokens[swapInfo.value.tokenOut].address;
    if (swapInfo.value.amountIn === 0 || swapInfo.value.amountIn === '') {
        return;
    }

    const path = await _getPath(
        fromToken,
        toToken,
        swapInfo.value.amountIn.toString(),
    );
    // console.log("path : ", path);

    let amountOutMinimum;
    let amountOutExpected;
    if (!swapInfo.value.defaultPath) {
        let allV2 = true;
        // let allV3 = true;
        for (let i = 0; i < path.paths[0].length; i++) {
            let pathaddr = path.paths[0][i];
            if (POOL[pathaddr].type === "V2") {
                // console.log("V2");
                // allV3 = false;
            } else if (POOL[pathaddr].type === "V3") {
                // console.log("V3");
                allV2 = false;
            }
        }

        if ((path.paths.length === 1) && allV2) {
            // console.log("ALL V2");
            let startToken = fromToken;
            let singlePath = [startToken];
            for (let i = 0; i < path.paths[0].length; i++) {
                let pathaddr = path.paths[0][i];
                if (POOL[pathaddr].token0 === startToken) {
                    singlePath.push(POOL[pathaddr].token1);
                    startToken = POOL[pathaddr].token1;
                } else {
                    singlePath.push(POOL[pathaddr].token0);
                    startToken = POOL[pathaddr].token0;
                }
            }
            // console.log("SINGLE PATH", singlePath);

            try {
                amountOutExpected = (await getAmountsOut_contract(
                    contract,
                    swapInfo.value.amountIn,
                    singlePath,
                ));
                const decimals = getDecimals(swapInfo.value.tokenOut);
                amountOutExpected = ethers.formatUnits(amountOutExpected, decimals);
                amountOutMinimum = amountOutExpected.toString() * (100 - swapInfo.value.slippage) / 100;
                amountOutMinimum = parseFloat(amountOutMinimum).toFixed(decimals).toString();
                amountOutMinimum = ethers.parseUnits(amountOutMinimum, decimals);
            } catch (error) {
                // console.error(error);
            }
            try {
                let deadline = ((await provider.getBlock('latest')).timestamp + 86400).toString();

                await v2OnlySwap_contract(
                    contract,
                    fromToken != WWEMIX ? swapInfo.value.amountIn : 0,
                    amountOutMinimum,
                    singlePath,
                    address,
                    deadline,
                    fromToken == WWEMIX ? swapInfo.value.amountIn : 0
                );
            } catch (error) {
                // console.error(error);
            }
            // } else if ((path.paths.length === 1) && allV3 && path.paths[0].length === 1) {
            //     // console.log("ALL V3");
            //     // console.log(path.paths.length, path.paths[0].length);

            //     try {
            //         amountOutExpected = (await multiPathSwapOutAmount_contract(
            //             contractView,
            //             path.path_bytecodes,
            //             address,
            //             ethers.MaxUint256,
            //             fromToken,
            //             toToken,
            //             path.optimal, // amountIns
            //             0, // amountOutMinimum
            //         ))[1];
            // amountOutExpected = ethers.formatUnits(amountOutExpected, getDecimals(swapInfo.value.tokenOut));
            // amountOutMinimum = (amountOutExpected.toString() * (100 - swapInfo.value.slippage) / 100).toString();
            // amountOutMinimum = ethers.parseUnits(amountOutMinimum, getDecimals(swapInfo.value.tokenOut));            //     } catch (error) {
            //         console.error(error);
            //     }
            //     try {
            //         let pathaddr = path.paths[0][0];

            //         await v3OnlySingleSwap_contract(
            //             contract,
            //             fromToken,
            //             toToken,
            //             POOL[pathaddr].fee,
            //             address,
            //             0,
            //             swapInfo.value.amountIn,
            //             amountOutMinimum,
            //             0,
            //             fromToken == WWEMIX ? swapInfo.value.amountIn : 0
            //         );
            //     } catch (error) {
            //         console.error(error);
            //     }
            // } else if ((path.paths.length === 1) && allV3 && path.paths[0].length !== 1) {
            //     // console.log("ALL V3");
            //     // console.log(path.paths.length, path.paths[0].length);

            //     let startToken = fromToken;
            //     let singlePath = [startToken];
            //     for (let i = 0; i < path.paths[0].length; i++) {
            //         let pathaddr = path.paths[0][i];
            //         singlePath.push(POOL[pathaddr].fee);
            //         if (POOL[pathaddr].token0 === startToken) {
            //             singlePath.push(POOL[pathaddr].token1);
            //             startToken = POOL[pathaddr].token1;
            //         } else {
            //             singlePath.push(POOL[pathaddr].token0);
            //             startToken = POOL[pathaddr].token0;
            //         }
            //     }
            //     // console.log("SINGLE PATH", singlePath);

            //     try {
            //         amountOutExpected = (await multiPathSwapOutAmount_contract(
            //             contractView,
            //             path.path_bytecodes,
            //             address,
            //             ethers.MaxUint256,
            //             fromToken,
            //             toToken,
            //             path.optimal, // amountIns
            //             0, // amountOutMinimum
            //         ))[1];
            // amountOutExpected = ethers.formatUnits(amountOutExpected, getDecimals(swapInfo.value.tokenOut));
            // amountOutMinimum = (amountOutExpected.toString() * (100 - swapInfo.value.slippage) / 100).toString();
            // amountOutMinimum = ethers.parseUnits(amountOutMinimum, getDecimals(swapInfo.value.tokenOut));            //     } catch (error) {
            //         console.error(error);
            //     }
            //     try {
            //         await v3OnlySwap_contract(
            //             contract,
            //             encodeV3Path(singlePath),
            //             address,
            //             0,
            //             swapInfo.value.amountIn,
            //             amountOutMinimum,
            //             fromToken == WWEMIX ? swapInfo.value.amountIn : 0
            //         );
            //     } catch (error) {
            //         console.error(error);
            //     }
        } else {
            try {
                amountOutExpected = (await multiPathSwapOutAmount_contract(
                    contractView,
                    path.path_bytecodes,
                    address,
                    ethers.MaxUint256,
                    fromToken,
                    toToken,
                    path.optimal, // amountIns
                    0, // amountOutMinimum
                ))[1];
                const decimals = getDecimals(swapInfo.value.tokenOut);
                amountOutExpected = ethers.formatUnits(amountOutExpected, decimals);
                amountOutMinimum = amountOutExpected.toString() * (100 - swapInfo.value.slippage) / 100;
                amountOutMinimum = parseFloat(amountOutMinimum).toFixed(decimals).toString();
                amountOutMinimum = ethers.parseUnits(amountOutMinimum, decimals);
            } catch (error) {
                // console.error(error);
            }
            try {
                let deadline = ((await provider.getBlock('latest')).timestamp + 86400).toString();

                await multiPathSwapOut_contract(
                    contract,
                    path.path_bytecodes, // path
                    address, // recipient
                    deadline, // deadline
                    fromToken, // tokenIn
                    toToken, // tokenOut
                    path.optimal, // amountIns
                    amountOutMinimum, // amountOutMinimum
                    fromToken == WWEMIX ? path.in : 0 // value
                );
                // console.log("response", response);
            } catch (error) {
                // console.error(error);
            }
        }
    } else { // swapInfo.value.defaultPath
        try {
            amountOutExpected = (await getAmountsOut_contract(
                contract,
                swapInfo.value.amountIn,
                DEFAULT_POOL_TOKENS[fromToken][toToken],
            ));
            const decimals = getDecimals(swapInfo.value.tokenOut);
            amountOutExpected = ethers.formatUnits(amountOutExpected, decimals);
            amountOutMinimum = amountOutExpected.toString() * (100 - swapInfo.value.slippage) / 100;
            amountOutMinimum = parseFloat(amountOutMinimum).toFixed(decimals).toString();
            amountOutMinimum = ethers.parseUnits(amountOutMinimum, decimals);
        } catch (error) {
            // console.error(error);
        }
        try {
            let deadline = ((await provider.getBlock('latest')).timestamp + 86400).toString();

            await v2OnlySwap_contract(
                contract,
                fromToken != WWEMIX ? swapInfo.value.amountIn : 0,
                amountOutMinimum,
                DEFAULT_POOL_TOKENS[fromToken][toToken],
                address,
                deadline,
                fromToken == WWEMIX ? swapInfo.value.amountIn : 0
            );
        } catch (error) {
            // console.error(error);
        }
    }

    await resetSwapInfo(swapInfo.value.tokenIn, swapInfo.value.tokenOut);
}

async function _getSufficientlyApproved() {
    if (!getIsConnected().value)
        return true; // others

    // wemix
    if (swapInfo.value.tokenIn === 0) {
        return true;
    }

    const tokenContract = getTokenContract(swapInfo.value.tokenIn);
    const address = getAddress().value;

    try {
        let response = await allowance_contract(tokenContract, address, ADDRESS.agg);
        return response >= swapInfo.value.amountIn;
    } catch (error) {
        // console.error(error);
        // throw error;
        return false;
    }
}

async function updateSwapOutAmount(noSignal) {
    // if (!getIsConnected().value)
    //     return;

    let fromToken = TOKEN.tokens[swapInfo.value.tokenIn].address;
    let toToken = TOKEN.tokens[swapInfo.value.tokenOut].address;
    if (swapInfo.value.amountIn === 0 || swapInfo.value.amountIn === '') {
        swapInfo.value.amountOut = '';
        return;
    }

    const contract = getAggContract();
    const contractView = getAggViewContract();
    const address = getAddress().value || "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266";

    isLoaded.value = noSignal || false;
    let path = await _getPath(
        fromToken,
        toToken,
        swapInfo.value.amountIn.toString(),
    );

    // console.log("PATH", path.paths);
    if (!swapInfo.value.defaultPath) {
        let allV2 = true;
        // let allV3 = true;
        for (let i = 0; i < path.paths[0].length; i++) {
            let pathaddr = path.paths[0][i];
            if (POOL[pathaddr].type === "V2") {
                // console.log("V2");
                // allV3 = false;
            } else if (POOL[pathaddr].type === "V3") {
                // console.log("V3");
                allV2 = false;
            }
        }

        if ((path.paths.length === 1) && allV2) {
            // console.log("ALL V2");
            let startToken = fromToken;
            let singlePath = [startToken];
            for (let i = 0; i < path.paths[0].length; i++) {
                let pathaddr = path.paths[0][i];
                if (POOL[pathaddr].token0 === startToken) {
                    singlePath.push(POOL[pathaddr].token1);
                    startToken = POOL[pathaddr].token1;
                } else {
                    singlePath.push(POOL[pathaddr].token0);
                    startToken = POOL[pathaddr].token0;
                }
            }
            // console.log("SINGLE PATH", singlePath);

            try {
                let response = (await getAmountsOut_contract(
                    contract,
                    swapInfo.value.amountIn,
                    singlePath,
                ));
                swapInfo.value.amountOut = response;
            } catch (error) {
                // console.error(error);
            }

            try {
                let estimation = await v2SingleSwap_estimate_contract(
                    contract,
                    fromToken != WWEMIX ? swapInfo.value.amountIn : 0,
                    0,
                    singlePath,
                    address,
                    ethers.MaxUint256,
                    fromToken == WWEMIX ? swapInfo.value.amountIn : 0
                );
                swapInfo.value.gas = estimation;
            } catch (error) {
                // console.error(error);
                swapInfo.value.gas = '';
            }

            updateSwapImageForDefaultPath(fromToken, toToken);

            // TODO
            // } else if ((path.paths.length === 1) && allV3) {
        } else {
            try {
                let response = await multiPathSwapOutAmount_contract(
                    contractView,
                    path.path_bytecodes,
                    address,
                    ethers.MaxUint256,
                    fromToken,
                    toToken,
                    path.optimal, // amountIns
                    0, // amountOutMinimum
                );
                swapInfo.value.amountOut = response[1];
            } catch (error) {
                // console.error(error);
            }

            try {
                let estimation = await multiPathSwapOut_estimate_contract(
                    contract,
                    path.path_bytecodes, // path
                    address, // recipient
                    ethers.MaxUint256, // deadline
                    fromToken, // tokenIn
                    toToken, // tokenOut
                    path.optimal, // amountIns
                    0, // amountOutMinimum
                    fromToken == WWEMIX ? path.in : 0 // value
                );
                swapInfo.value.gas = estimation;
            } catch (error) {
                // console.error(error);
                swapInfo.value.gas = '';
            }

            updateSwapImage(path.paths);
        }
    } else { // Default Path
        try {
            let response = (await getAmountsOut_contract(
                contract,
                swapInfo.value.amountIn,
                DEFAULT_POOL_TOKENS[fromToken][toToken],
            ));
            swapInfo.value.amountOut = response;
        } catch (error) {
            // console.error(error);
        }

        try {
            let estimation = await v2SingleSwap_estimate_contract(
                contract,
                fromToken != WWEMIX ? swapInfo.value.amountIn : 0,
                0,
                DEFAULT_POOL_TOKENS[fromToken][toToken],
                address,
                ethers.MaxUint256,
                fromToken == WWEMIX ? swapInfo.value.amountIn : 0
            );
            swapInfo.value.gas = estimation;
        } catch (error) {
            // console.error(error);
            swapInfo.value.gas = '';
        }

        updateSwapImageForDefaultPath(fromToken, toToken);
    }

    // console.log("updateSwapOutAmount", "gas", swapInfo.value.gas);

    try {
        isApproved.value = await _getSufficientlyApproved();
    } catch (error) {
        // console.error(error);
    }

    isLoaded.value = true;
}

async function updateBalance() {
    if (!getIsConnected().value) {
        return;
    }

    // await getBalanceOf(swapInfo.value.tokenIn).then((success) => {
    //     swapInfo.value.balanceIn = success;
    //     // console.log("balance of tokenIn : ", swapInfo.value.balanceIn)
    // });

    // await getBalanceOf(swapInfo.value.tokenOut).then((success) => {
    //     swapInfo.value.balanceOut = success;
    //     // console.log("balance of tokenIn : ", swapInfo.value.balanceOut)
    // });

    // await getTokenInfoWithBalance();

    const balances = await getBalancesOf();

    swapInfo.value.balanceIn = balances[swapInfo.value.tokenIn];
    swapInfo.value.balanceOut = balances[swapInfo.value.tokenOut];
}

function updateSwapImage(path) {
    // based on path
    swapImage.value = [];
    for (let i = 0; i < path.length; i++) {
        swapImage.value.push([]);
        let startToken = TOKEN.tokens[swapInfo.value.tokenIn].address;
        for (let j = 0; j < path[i].length; j++) {
            let pathaddr = path[i][j];
            if (pathaddr in POOL) {
                // console.log("swapImage.value i,j", i, j, pathaddr)
                if (POOL[pathaddr].token0 === startToken) {
                    swapImage.value[i][j] = [POOL[pathaddr].image0, POOL[pathaddr].image1, POOL[pathaddr].fee / 10000];
                    startToken = POOL[pathaddr].token1;
                } else {
                    swapImage.value[i][j] = [POOL[pathaddr].image1, POOL[pathaddr].image0, POOL[pathaddr].fee / 10000];
                    startToken = POOL[pathaddr].token0;
                }
                // console.log("swapImage.value", swapImage.value[i][j])
            }
            else {
                swapImage.value[i][j] = [DEFAULT_POOL_ICON, DEFAULT_POOL_ICON, '-.--'];
            }
        }
    }
    // console.log("swapImage : ", swapImage.value[0][0]);
}

function updateSwapImageForDefaultPath(tokenIn, tokenOut) {
    const path = DEFAULT_POOL_POOL[tokenIn][tokenOut];
    updateSwapImage([path]);
}

async function resetSwapInfo(tokenIn, tokenOut) {
    swapInfo.value.tokenIn = tokenIn;
    swapInfo.value.tokenOut = tokenOut;
    swapInfo.value.amountIn = '';
    swapInfo.value.amountOut = '';
    swapInfo.value.balanceIn = '';
    swapInfo.value.balanceOut = '';
    swapInfo.value.path = [];
    swapInfo.value.savings = '';
    swapInfo.value.gas = '';
    swapInfo.value.defaultPath = false;
    swapImage.value = [];
    try {
        isApproved.value = await _getSufficientlyApproved();
        // console.log(isApproved.value);
    } catch (error) {
        // console.error(error);
    }
    try {
        await updateBalance();
    } catch (error) {
        // console.error(error);
    }
}

async function setSwapAmount(amount) {
    if (Number.isNaN(amount)) amount = '';
    swapInfo.value.amountIn = amount;
}


function debounce(func, wait) {
    let timeout;

    return function executedFunction(...args) {
        return new Promise((resolve, reject) => {
            const later = async () => {
                clearTimeout(timeout);
                try {
                    const result = await func(...args);
                    resolve(result);
                } catch (error) {
                    reject(error);
                }
            };

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        });
    };
}

export {
    getTokenList,
    getSwapInfo,
    getSwapImage,
    getIsApproved,
    getIsLoaded,
    resetSwapInfo,
    setSwapAmount,
    getDecimals,
    approveMax,
    swap,
    updateSwapOutAmount,
    updateBalance,
    debounce
};