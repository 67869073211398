const GAS_SETTINGS = {
    maxFeePerGas: 100.000000001 * 10 ** 9,
    maxPriorityFeePerGas: 100 * 10 ** 9,
}

/* send - multiPathSwapOut */
async function multiPathSwapOut_contract(_contract, _paths, _recipient, _deadline, _tokenIn, _tokenOut, _amountIns, _amountOutMinimum, _value) {
    // try {
    // console.log("-- [request] multiPathSwapOut");
    let response = await _contract.multiPathSwapOut({
        paths: _paths,
        recipient: _recipient,
        deadline: _deadline,
        tokenIn: _tokenIn,
        tokenOut: _tokenOut,
        amountIns: _amountIns,
        amountOutMinimum: _amountOutMinimum
    }, {
        value: _value,
        ...GAS_SETTINGS,
    });
    // console.log("-- [response] multiPathSwapOut :", response);
    const result = await response.wait();
    // console.log("-- [result] multiPathSwapOut :", result);
    return result;
    // } catch (error) {
    // console.log(error);
    // throw error;
    // }
}

async function approve_contract(_contract, _spender, _value) {
    // try {
    const response = await _contract.approve(_spender, _value, {
        ...GAS_SETTINGS,
    });
    const result = await response.wait();
    return result;
    // } catch (error) {
    // console.log(error);
    // throw error;
    // }
}

async function v3OnlySingleSwap_contract(_contract, _tokenIn, _tokenOut, _fee, _recipient, _deadline, _amountIn, _amountOutMinimum, _sqrtPriceLimitX96, _value) {
    if (_amountIn == 0) {
        throw new Error("Not a valid amount");
    }
    _sqrtPriceLimitX96;

    // try {
    let response = await _contract.exactInputSingle({
        tokenIn: _tokenIn,
        tokenOut: _tokenOut,
        fee: _fee,
        recipient: _recipient,
        deadline: _deadline,
        amountIn: _amountIn,
        amountOutMinimum: _amountOutMinimum,
        sqrtPriceLimitX96: "0",
    }, {
        value: _value,
        ...GAS_SETTINGS,
    });
    const result = await response.wait();
    return result;
    // } catch (error) {
    // console.error(error);
    // throw error;
    // }
}

async function v3OnlySwap_contract(_contract, _path, _recipient, _deadline, _amountIn, _amountOutMinimum, _value) {
    if (_amountIn == 0) {
        throw new Error("Not a valid amount");
    }

    // try {
    let response = await _contract.exactInput({
        path: _path,
        recipient: _recipient,
        deadline: _deadline,
        amountIn: _amountIn,
        amountOutMinimum: _amountOutMinimum
    }, {
        value: _value,
        ...GAS_SETTINGS,
    });
    const result = await response.wait();
    return result;
    // } catch (error) {
    // console.error(error);
    // throw error;
    // }
}

async function v2OnlySwap_contract(_contract, _amountIn, _amountOutMin, _path, _recipient, _deadline, _value) {
    if ((_amountIn == 0 || _value != 0) && (_amountIn != 0 || _value == 0)) {
        throw new Error("Not a valid amount");
    }

    // try {
    let result;
    if (_path[_path.length - 1] == "0x7D72b22a74A216Af4a002a1095C8C707d6eC1C5f") {
        let response = await _contract.swapExactTokensForWEMIX(
            _amountIn,
            _amountOutMin,
            _path,
            _recipient,
            _deadline,
            {
                ...GAS_SETTINGS,
            }
        );
        result = await response.wait();
    } else if (_value == 0) {
        let response = await _contract.swapExactTokensForTokens(
            _amountIn,
            _amountOutMin,
            _path,
            _recipient,
            _deadline,
            {
                ...GAS_SETTINGS,
            }
        );
        result = await response.wait();
    } else {
        let response = await _contract.swapExactWEMIXForTokens(
            _amountOutMin,
            _path,
            _recipient,
            _deadline,
            {
                value: _value,
                ...GAS_SETTINGS,
            }
        );
        result = await response.wait();
    }
    return result;
    // } catch (error) {
    // console.log(error);
    // throw error;
    // }
}

/* view - multiPathSwapOutAmount static call */
async function multiPathSwapOutAmount_contract(_contract, _paths, _recipient, _deadline, _tokenIn, _tokenOut, _amountIns, _amountOutMinimum) {
    // try {
    // console.log("-- [request] multiPathSwapOutAmount");
    let response = await _contract.getMultiPathSwapOutAmount.staticCall({
        paths: _paths,
        recipient: _recipient,
        deadline: _deadline,
        tokenIn: _tokenIn,
        tokenOut: _tokenOut,
        amountIns: _amountIns,
        amountOutMinimum: _amountOutMinimum
    });
    // console.log("-- [response] multiPathSwapOutAmount :", response);
    return response;
    // } catch (error) {
    // console.log(error);
    // throw error;
    // }
}

async function getAmountsOut_contract(_contract, _amountIn, _path) {
    let response = await _contract.getAmountsOut(
        _amountIn,
        _path
    );
    return response[response.length - 1];
}

// async function multiPathSwapOut_static_contract(_contract, _paths, _recipient, _deadline, _tokenIn, _tokenOut, _amountIns, _amountOutMinimum, _value) {
//     try {
//         // console.log("-- [request] multiPathSwapOut staticCall");
//         let response = await _contract.multiPathSwapOut.staticCall({
//             paths: _paths,
//             recipient: _recipient,
//             deadline: _deadline,
//             tokenIn: _tokenIn,
//             tokenOut: _tokenOut,
//             amountIns: _amountIns,
//             amountOutMinimum: _amountOutMinimum
//         }, {
//             value: _value,
//             ...GAS_SETTINGS,
//         });
//         // console.log("-- [response] multiPathSwapOut staticCall:", response);
//         return response;
//     } catch (error) {
//         console.log(error);
//         throw error;
//     }
// }

async function allowance_contract(_contract, _owner, _spender) {
    // try {
    let response = await _contract.allowance(_owner, _spender);
    return response;
    // } catch (error) {
    // console.log(error);
    // throw error;
    // }
}

/* estimation */
async function multiPathSwapOut_estimate_contract(_contract, _paths, _recipient, _deadline, _tokenIn, _tokenOut, _amountIns, _amountOutMinimum, _value) {
    // try {
    // console.log("-- [request] multiPathSwapOut");
    let result = await _contract.multiPathSwapOut.estimateGas({
        paths: _paths,
        recipient: _recipient,
        deadline: _deadline,
        tokenIn: _tokenIn,
        tokenOut: _tokenOut,
        amountIns: _amountIns,
        amountOutMinimum: _amountOutMinimum
    }, {
        value: _value,
        ...GAS_SETTINGS,
    });
    // console.log("-- [response] multiPathSwapOut :", response);
    return result;
    // } catch (error) {
    //     console.log(error);
    //     throw error;
    // }
}

async function v2SingleSwap_estimate_contract(_contract, _amountIn, _amountOutMin, _path, _recipient, _deadline, _value) {
    if ((_amountIn == 0 || _value != 0) && (_amountIn != 0 || _value == 0)) {
        throw new Error("Not a valid amount");
    }

    // try {
    if (_path[_path.length - 1] == "0x7D72b22a74A216Af4a002a1095C8C707d6eC1C5f") { // WWEMIX
        let result = await _contract.swapExactTokensForWEMIX.estimateGas(
            _amountIn,
            _amountOutMin,
            _path,
            _recipient,
            _deadline,
            {
                ...GAS_SETTINGS,
            }
        );
        return result;
    } else if (_value == 0) {
        let result = await _contract.swapExactTokensForTokens.estimateGas(
            _amountIn,
            _amountOutMin,
            _path,
            _recipient,
            _deadline,
            {
                ...GAS_SETTINGS,
            }
        );
        return result;
    } else {
        let result = await _contract.swapExactWEMIXForTokens.estimateGas(
            _amountOutMin,
            _path,
            _recipient,
            _deadline,
            {
                value: _value,
                ...GAS_SETTINGS,
            }
        );
        return result;
    }
    // } catch (error) {
    // console.log(error);
    // throw error;
    // }
}

export { multiPathSwapOut_contract, approve_contract }
export { multiPathSwapOutAmount_contract, allowance_contract /* multiPathSwapOut_static_contract */ }
export { multiPathSwapOut_estimate_contract }
export { v2OnlySwap_contract, v2SingleSwap_estimate_contract, getAmountsOut_contract }
export { v3OnlySingleSwap_contract, v3OnlySwap_contract }
