<template>
  <div class="modal" v-if="showModal" @click="handleOutsideClick">
    <transition name="modal-content-fade">
      <div class="modal-content">
        <ul class="wallet-list">
          <li class="wallet-item" @click="selectWallet('una')">
            <div class="wallet-info">
              <div class="wallet-log-symbol">
                <img
                  src="../assets/wallets/unawallet.webp"
                  class="wallet-logo"
                />
                <div class="wallet-symbol">una Wallet</div>
              </div>
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="link-arrow"
              >
                <g
                  opacity="0.4"
                  stroke="#000"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </g>
              </svg>
            </div>
          </li>
          <li class="wallet-item" @click="selectWallet('wemix')">
            <div class="wallet-info">
              <div class="wallet-log-symbol">
                <img
                  src="../assets/wallets/wemixwallet.png"
                  class="wallet-logo"
                />
                <div class="wallet-symbol">WEMIX Wallet</div>
              </div>
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="link-arrow"
              >
                <g
                  opacity="0.4"
                  stroke="#000"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </g>
              </svg>
            </div>
          </li>
          <li class="wallet-item" @click="selectWallet('metamask')">
            <!-- group 1 -->
            <div class="wallet-info">
              <div class="wallet-log-symbol">
                <img src="../assets/wallets/metamask.png" class="wallet-logo" />
                <div class="wallet-symbol">MetaMask</div>
              </div>
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="link-arrow"
              >
                <g
                  opacity="0.4"
                  stroke="#000"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </g>
              </svg>
            </div>
            <!-- group 2 -->
            <!-- <div class="wallet-warn">
              <span style="display: inline-block; width: 4px"></span>
              <img
                src="../assets/warning.jpeg"
                alt="Warning"
                class="warn-mark"
                style="width: 14px; height: 14px"
              />
              Please ensure that the MetaMask Wallet is set to the WEMIX
              mainnet.
            </div> -->
          </li>
        </ul>
        <button @click="closeModal">CLOSE</button>
      </div>
    </transition>
  </div>
</template>
  
<script>
export default {
  props: {
    showModal: Boolean,
  },
  methods: {
    selectWallet(wallet) {
      this.$emit("select", wallet);
      this.closeModal();
    },
    closeModal() {
      this.$emit("close");
    },
    handleKeydown(event) {
      if (event.key === "Escape") {
        this.closeModal();
      }
    },
    handleOutsideClick(event) {
      if (event.target === event.currentTarget) {
        this.closeModal();
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>
  
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-show {
  height: 100%;
}

/* Styles for small screens */
@media (max-width: 430px) {
  .modal {
    overflow-x: hidden;
    align-items: flex-end;
    position: fixed;
    bottom: 0;
  }
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-bottom-left-radius: 46px;
  border-bottom-right-radius: 46px;
  border-top-left-radius: 46px;
  border-top-right-radius: 46px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 420px;
  /* min-width: 300px; */
  width: 90%;
  /* max-height: 95%; */
  /* overflow: auto; */
  animation: appear 0.3s ease;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0.96);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 430px) {
  .modal-content {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    align-items: flex-end;
    position: fixed;
    bottom: 0;
    animation: slideUp 0.4s ease;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.wallet-list {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 80%;
  max-height: 280px;
  overflow-y: auto; /* Apply scroll to the UL */
}

.wallet-list li {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.wallet-list li:hover {
  border-radius: 30px;
  background-color: #f8f8f8;
}

.wallet-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.wallet-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.wallet-logo {
  width: 40px;
  height: 40px;
  border-radius: 30%;
  margin-left: 20px;
}

.wallet-symbol {
  font-weight: 700;
  margin-left: 20px;
  /* 폰트 */
  color: rgb(97, 97, 97);
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.wallet-log-symbol {
  display: flex;
  align-items: center;
  width: 180px;
}

button {
  /* width: 100%; */
  width: 100%;
  background-color: black;
  color: white;
  padding: 15px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 30px;
  /* 폰트 */
  font-size: 20px;
  background-color: black;
  font-weight: 700;
  font-style: normal;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #404040;
}

.link-arrow {
  margin-right: 20px;
}

.wallet-warn {
  width: 90%;
  display: flex;
  align-items: center;
  border: 1px solid red; /* Adding a red border */
  background-color: rgba(255, 0, 0, 0.1); /* Transparent red background */
  margin-top: 3px;
  padding: 10px;
  color: red;
  font-size: 10px;
  font-weight: 400;
}

.warn-mark {
  margin-right: 6px;
}
</style>
