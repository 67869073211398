<script>
import WalletSelectModal from "./WalletSelectModal.vue";
import ColorSelectModal from "./ColorSelectModal.vue";

import {
  getWalletKind,
  getProvider,
  setProvider,
  resetProvider,
  getAddress,
  connectContract,
  getIsConnected,
} from "../assets/js/interface_request.js";
import { ethers } from "ethers";
import { WalletConnectModal } from "@walletconnect/modal";
// import QRCode from "qrcode";

import { resetSwapInfo } from "../assets/js/webapp_context.js";

// TODO: ENV
const projectId = "0c06b9b2105f1d8fcced34f829538260";
const chainId = "0x457";
const chainName = "WEMIX";
const rpcUrl = "https://api.wemix.com";
const nativeCurrency = { name: "WEMIX", decimals: 18, symbol: "WEMIX" };

export default {
  name: "MainMenu",
  components: {
    WalletSelectModal,
    ColorSelectModal,
  },
  data() {
    return {
      svgFilter: "none",
      // svgFillColor: "none",
      // svgFilter: "grayscale(100%)",
      svgFillColor: window.localStorage.getItem("color") || "#000000",

      connecting: false,

      hover: false,

      wallet: getWalletKind(),

      web3Modal: new WalletConnectModal({
        projectId,
        themeMode: "light",
        themeVariables: {
          "--wcm-accent-color": "#000000",
          "--wcm-background-color": "#000000",
        },
        explorerRecommendedWalletIds: [
          "8eefa62c2f86b4c73bd68cf5cb178e09d15cbf21399ac5aea234d2b616e9ae9d",
          "fa9c3adc4f0bbe263db1565d200f776e5da900ead0f1914e0ecbf8b313d268e9",
          "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
          // "225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f",
        ],
      }),

      // provider: getProvider(),
      address: getAddress(),
      connected: getIsConnected(),

      showWModal: false,
      showCModal: false,

      isColorTooltipVisible: false,
    };
  },
  methods: {
    sleep(ms) {
      return new Promise((r) => setTimeout(r, ms));
    },
    showCModalOn: async function () {
      this.showCModal = true;
    },
    showColorTooltip: async function () {
      this.isColorTooltipVisible = true;
      await this.sleep(3000);
      this.hideColorTooltip();
    },
    hideColorTooltip() {
      this.isColorTooltipVisible = false;
    },

    connectOnClick: async function () {
      this.showWModal = true;
    },
    selectWallet: async function (wallet) {
      this.wallet = wallet;
      // console.log("selectWallet", wallet, this.wallet);

      if (this.wallet === "metamask") {
        this.connectMetamask();
      } else if (this.wallet === "wemix" || this.wallet === "una") {
        this.connectWalletConnect();
      } else {
        throw Error("Not supported.");
      }
    },

    handleAction: async function () {
      // console.log("handleAction");
      if (this.connected) {
        if (this.wallet === "metamask") {
          await this.disconnectWallet();
        } else if (this.wallet === "wemix" || this.wallet === "una") {
          await this.disconnectWallet();
        } else {
          throw Error("Not supported.");
        }
      } else if (this.isMobile) {
        this.connecting = true;
        await this.connectWalletConnect();
        this.wallet = "wemix"; // walletconnect
      } else {
        this.connecting = true;
        await this.connectOnClick();
      }
    },

    shortenAddress(address) {
      if (!address || address.length < 11) {
        return address;
      }

      // if (window.innerWidth < 430) {
      // return (
      // address.substring(0, 8) +
      // "..." +
      // address.substring(address.length - 6, address.length)
      // );
      // } else {
      return (
        address.substring(0, 6) +
        "..." +
        address.substring(address.length - 4, address.length)
      );
      // }
    },

    setProvider: async function () {
      await setProvider();
      let _provider = getProvider();

      _provider.on("display_uri", async (uri) => {
        console.log("display_uri");

        if (this.address) {
          _provider.removeListener("display_uri", function () {
            // console.log("remove provider_uri event");
          });
          return;
        }

        // const url = await QRCode.toDataURL(uri, {
        //   errorCorrectionLevel: "H",
        //   margin: 0,
        // });
        // this.qrCodeDataUrl = url;

        this.web3Modal.closeModal();
        await this.web3Modal.openModal({ uri });
        this.connecting = false;
      });

      await setProvider(_provider);
    },
    connectWalletConnect: async function (session = undefined) {
      try {
        this.connecting = true;
        let preprovider;

        if (!session) {
          // console.log("New Connect.");

          await this.setProvider();
          preprovider = getProvider();

          const proposalNamespace = {
            eip155: {
              methods: [
                "eth_sendTransaction",
                "eth_signTransaction",
                "eth_sign",
                "personal_sign",
                "eth_signTypedData",
                "wallet_watchAsset",
                "wallet_addEthereumChain",
                "wallet_switchEthereumChain",
              ],
              chains: ["eip155:1111"],
              events: ["chainChanged", "accountsChanged"],
              rpcMap: { ["1111"]: "https://api.wemix.com" },
            },
          };
          await preprovider.connect({
            namespaces: proposalNamespace,
          });
        } else {
          // console.log("Preconnect.");

          await setProvider();
          preprovider = getProvider();
          preprovider.client = session;
        }
        // preprovider.on("accountsChanged", async (data) => {
        //   console.log("accountsChanged", data);
        //   await this.disconnectWallet();
        // });
        // preprovider.on("chainChanged", async () => {
        //   console.log("Chain Changed");
        //   // this.onChainChanged(chainId);
        //   await this.disconnectWallet();
        // });
        // preprovider.on("session_expire", async () => {
        //   console.log("Session Expire");
        //   await this.disconnectWallet();
        // });
        // preprovider.on("session_delete", async ({ id, topic }) => {
        //   console.log("Session Delete", id, topic);
        //   await this.disconnectWallet();
        // });
        window.localStorage.setItem("session", preprovider.session);

        this.connected = true;
        // console.log(this.connected);

        const provider = new ethers.BrowserProvider(preprovider);
        await setProvider(provider);

        const signer = await provider.getSigner();
        this.address = signer.address;
        // console.log(this.address);

        await connectContract(signer);
        await resetSwapInfo(0, 1);

        // console.log("modal close");
        this.web3Modal.closeModal();

        window.localStorage.setItem("wallet", "walletconnect");
      } catch (error) {
        this.connected = false;
        this.address = undefined;
        this.wallet = "";
        // console.error(error);
        throw error;
      } finally {
        this.connecting = false;
      }
    },
    connectMetamask: async function () {
      this.connecting = true;
      try {
        let _provider = new ethers.BrowserProvider(window.ethereum);

        // if (_provider) {
        //   let hasSigner = await _provider.hasSigner();
        //   if (!hasSigner) return;

        //   // const currentChainId = (await _provider.getNetwork()).chainId;
        //   // if (currentChainId != chainId) return;
        // }

        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: chainId }],
          });
        } catch (error) {
          // console.error(error);
          if (error.code === 4902) {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: chainId,
                  chainName: chainName,
                  rpcUrls: [rpcUrl],
                  nativeCurrency: nativeCurrency,
                },
              ],
            });
          }
        }

        await _provider.send("eth_requestAccounts", []); // this promps user to connect metamask
        await setProvider(_provider);

        this.connected = true;
        // console.log(this.connected);

        const signer = await _provider.getSigner();
        this.address = signer.address;
        // console.log(this.address);

        await connectContract(signer);
        await resetSwapInfo(0, 1);

        window.localStorage.setItem("wallet", "metamask");
      } catch (error) {
        this.connected = false;
        this.address = undefined;
        this.wallet = "";
        // console.error(error);
        throw error;
      } finally {
        this.connecting = false;
      }
    },
    disconnectWallet: async function () {
      resetProvider();

      // window.localStorage.clear();
      window.localStorage.removeItem("wallet");
      window.localStorage.removeItem("session");

      this.connected = false;
      this.address = undefined;
      this.wallet = "";

      await connectContract(
        new ethers.JsonRpcProvider("https://api.wemix.com")
      );

      await resetSwapInfo(0, 1);
    },

    preconnectWallet: async function () {
      const prewallet = window.localStorage.getItem("wallet");
      // console.log("prewallet", prewallet);

      if (prewallet === "metamask") {
        this.wallet = "metamask";
        await this.connectMetamask();
      } else if (prewallet === "walletconnect") {
        this.wallet = "wemix"; // walletconnect
        const session = window.localStorage.getItem("session");
        // console.log("session", session);
        await this.connectWalletConnect(session);
      } else {
        throw Error("Not Supported.");
      }
    },

    localStorageClean: function () {
      window.localStorage.clear();
    },
  },
  async mounted() {
    function timeout(ms, promise) {
      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          reject(new Error("Timeout."));
        }, ms);
        promise.then(resolve, reject);
      });
    }

    // this.localStorageClean();

    this.connecting = true;
    try {
      await timeout(3000, this.preconnectWallet());
      await this.preconnectWallet();
      // console.log("connected to preconnected wallet.");
    } catch (error) {
      // console.error(error);
      // console.log("connected to default provider.");
      await this.disconnectWallet();
    }
    this.connecting = false;
  },

  computed: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
};
</script>

<template>
  <WalletSelectModal
    :showModal="showWModal"
    @select="(wallet) => this.selectWallet(wallet)"
    @close="
      () => {
        showWModal = false;
        if (wallet === '') {
          connecting = false;
        }
      }
    "
  />

  <ColorSelectModal
    :showModal="showCModal"
    @select="
      (color) => {
        this.svgFillColor = color;
      }
    "
    @close="
      () => {
        showCModal = false;
      }
    "
  />

  <div class="d-flex align-items-center menu">
    <!-- 로고는 좌측 정렬 -->
    <div class="logo-container">
      <a href="https://opt.finance/">
        <img src="../assets/logo.png" class="logo" />
      </a>
      <a href="https://opt.finance/" class="logo-name">OPT</a>
    </div>

    <!-- 메뉴는 가운데 -->
    <div class="menu-container">
      <!-- <a href="./" class="menu-title">SWAP</a> -->
      <!-- <a href="https://wemix.fi/swap" target="_blank">WEMIXFI</a> -->
    </div>

    <!-- <img :src="qrCodeDataUrl" alt="QR Code" /> -->

    <!-- 지갑 연결은 우측 정렬 -->
    <div class="setting-container">
      <!-- <button
        class="network-button"
        @click="web3Modal.open({ view: 'Networks' })"
      >
        <img src="../assets/wemix.svg" alt="Network" />
      </button> -->

      <!-- <VSwatches v-model="svgFillColor" /> -->
      <div
        class="network-button"
        inline
        @click="showCModalOn"
        v-on:mouseover="showColorTooltip"
        v-on:mouseleave="hideColorTooltip"
      >
        <svg
          :style="{ filter: svgFilter }"
          width="36px"
          height="36px"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 0C9.8516 0 0 9.8516 0 22C0 23.3156 0.1232 24.6004 0.3432 25.85H5.2272C5.852 25.85 6.226 25.6476 6.468 25.256L8.184 22.4136L10.3796 29.2292C10.5952 29.9156 11.1056 30.272 11.792 30.2764C12.496 30.2808 13.0768 29.8496 13.2132 29.1456L14.806 21.6392L16.5396 33.6072C16.6452 34.3948 17.028 34.7908 17.82 34.7908H17.8288C18.6164 34.7908 18.9948 34.364 19.1048 33.5808L22.638 10.2872C22.7392 9.5656 22.2332 8.8968 21.5116 8.7956C20.7944 8.6988 20.1212 9.2004 20.02 9.922L17.842 24.8556L16.3768 14.8896C16.28 14.124 15.6992 13.6488 15.0656 13.6356C14.3484 13.618 13.816 14.0668 13.6664 14.828L11.5632 24.5784L9.8824 19.3468C9.7504 18.7792 9.2444 18.3568 8.69 18.2908C7.9244 18.1984 7.4888 18.5944 7.1676 19.118L4.5672 23.21H3.014C2.9876 22.8096 2.97 22.4048 2.97 22C2.97 11.4884 11.4884 2.97 22 2.97C30.4744 2.97 37.6552 8.514 40.1148 16.17H43.2124C40.656 6.8508 32.1332 0 22 0Z"
            :fill="svgFillColor"
          />
          <path
            d="M43.6568 18.15H38.764C38.1392 18.15 37.7652 18.3524 37.5232 18.744L35.8072 21.5864L33.6116 14.7708C33.396 14.0844 32.8856 13.728 32.1992 13.7236C31.4952 13.7192 30.9144 14.1504 30.778 14.8544L29.1852 22.3608L27.4516 10.3928C27.346 9.6052 26.9632 9.2092 26.1712 9.2092H26.1624C25.3748 9.2092 24.9964 9.636 24.8864 10.4192L21.3532 33.7128C21.252 34.4344 21.758 35.1032 22.4796 35.2044C23.1968 35.3012 23.87 34.7996 23.9712 34.078L26.1492 19.1444L27.6144 29.1104C27.7112 29.876 28.292 30.3512 28.9256 30.3644C29.6428 30.382 30.1752 29.9332 30.3248 29.172L32.428 19.4216L34.1088 24.6532C34.2408 25.2208 34.7468 25.6432 35.3012 25.7092C36.0668 25.8016 36.5024 25.4056 36.8236 24.882L39.424 20.79H40.986C41.0124 21.1904 41.03 21.5952 41.03 22C41.03 32.5116 32.5116 41.03 22 41.03C13.5256 41.03 6.3448 35.486 3.8852 27.83H0.7876C3.344 37.1492 11.8712 44 22 44C34.1484 44 44 34.1484 44 22C44 20.6844 43.8768 19.3996 43.6568 18.15Z"
            :fill="svgFillColor"
          />
        </svg>
        <div
          v-if="isColorTooltipVisible"
          class="tooltip-color-box tooltip-color"
        >
          Set a personalized color
        </div>
      </div>

      <button
        class="btn btn-primary"
        @click="handleAction"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
      >
        <div v-if="connecting" class="wallet-spinner-container">
          <div class="wallet-spinner"></div>
        </div>
        <span v-if="!connected"> CONNECT </span>
        <span v-else-if="connected && !hover">{{
          shortenAddress(address)
        }}</span>
        <span v-else>DISCONNECT</span>
      </button>
    </div>
  </div>
</template>

<style>
.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}

.logo-container {
  /* width: 204px;
  max-width: 204px; */
  width: 160px;
  max-width: 160px;
  /* min-width: 204px; */
  height: 44px;
  display: flex;
  align-items: center;
}

.logo {
  width: 44px;
  margin-right: 12px;
}

.logo-container a {
  height: 44px;
}

/* 하이퍼링크 지우기 */
.logo-container a,
.menu-container a {
  color: inherit;
  text-decoration: none;
}

.logo-name {
  font-size: 28px;
  /* font-family: "source-han-sans-cjk-ko", sans-serif; */
  font-weight: 900;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 430px) {
  .logo-name {
    display: none;
  }
  .logo-container {
    width: 44px;
    margin-right: 12px;
  }
}

.menu-container {
  height: 44px;

  /* margin: 0 12px; */
  text-decoration: none;
  /* 폰트 */
  font-size: 20px;
  /* font-family: "source-han-sans-cjk-ko", sans-serif; */
  font-weight: 700;
  font-style: normal;
}

@media (max-width: 660px) {
  .menu-container {
    display: none;
  }
}

/* .menu-title {
  margin-right: 28px;
} */

.menu-item {
  font-size: 18px;
}

.setting-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
}

.btn {
  display: flex;
  height: 44px;
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
  width: 160px;
  padding: 10px 10px;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  background-color: black;
  font-weight: 500;
  font-style: normal;
}

@media (max-width: 530px) {
  .btn {
    border-radius: 15px;
  }
}

@media (max-width: 430px) {
  .setting-container {
    width: 100%;
    /* height: 56px; */
  }
}

@media (max-width: 430px) {
  .btn {
    width: 100%;
    /* height: 56px; */
  }
}

@media (max-width: 430px) {
  .menu {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 0px;
    padding-right: 0px;
    /* height: 56px; */
  }
}

.btn:hover {
  background-color: #404040;
}

/* 
@media (max-width: 530px) {
  .btn {
  }
}
*/

.network-button {
  width: 36px;
  height: 36px;
  margin-right: 12px;
  /* margin-left: 12px; */
}

.wallet-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 16px; */
  /* height: 16px; */
  margin-right: 12px;
}
.wallet-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border-left-color: grey;
  animation: spin 1s ease infinite;
}

.tooltip-color-box {
  transform: translatex(-32px) translatey(6px);
}

.tooltip-color {
  position: absolute;
  padding: 10px;
  /* border: 1px solid grey; */
  background: black;
  border-radius: 8px;
  color: white;
  white-space: pre-line;
  box-shadow: 0 2px 150px rgb(0, 0, 0, 0.15);
  /* 폰트 */
  font-size: 12px;
  /* font-family: "poppins", sans-serif; */
  font-weight: 400;
  font-style: normal;
}

.tooltip-color::before {
  content: "";
  position: absolute;
  top: -6px; /* Half of the arrow's height */
  right: 60%;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black; /* Same color as the text bubble */
}
</style>
