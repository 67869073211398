<template>
  <div class="rounded-top">
    <!-- <div class="swap-top-title">SWAP</div> -->
    <div class="circle-container">
      <div
        v-for="(circle, index) in circles"
        class="circle"
        :key="index"
        :class="{ filled: circle, 'last-circle': index === circles.length - 1 }"
      ></div>
    </div>
  </div>
  <div class="bottom-div">
    <div class="updated-text">Last Updated: {{ time }}</div>
  </div>
</template>

<script>
import {
  updateSwapOutAmount,
  updateBalance,
} from "../assets/js/webapp_context.js";
export default {
  name: "SwapTitle",
  data() {
    return {
      circles: [false, false, false, false, false], // Initially, all circles are not filled
      time: "-",
    };
  },
  mounted() {
    this.startFillingInterval();
  },
  methods: {
    startFillingInterval() {
      setInterval(() => {
        const filledIndex = this.circles.findIndex((circle) => !circle);
        if (filledIndex !== -1) {
          this.circles[filledIndex] = true;
        } else {
          // refresh here
          updateSwapOutAmount(true);
          updateBalance();
          this.updateTime();
          this.circles = [false, false, false, false, false];
        }
      }, 1000);
    },
    updateTime() {
      const now = new Date();
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      };
      this.time = now
        .toLocaleTimeString(navigator.language, options)
        .replace(/(\d+)\/(\d+)\/(\d+)/, "$3.$1.$2");
    },
  },
};
</script>

<style>
.rounded-top {
  display: flex;
  align-items: center;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: black;
  color: white;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 14px;
  margin-top: 20px;
  width: 100%;
  max-width: 560px;
}

.bottom-div {
  display: flex;
  background-color: black;
  color: white;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 10px;
  width: 100%;
  max-width: 560px;
}

.swap-top-title {
  padding-left: 25px;
  /* 폰트 */
  font-size: 20px;
  /* font-family: "source-han-sans-cjk-ko", sans-serif; */
  font-weight: 500;
  font-style: normal;
}

.circle-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding: 10px;
}

.circle {
  width: 6px;
  height: 6px;
  border: 2.5px solid white;
  border-radius: 30px;
  margin-top: -8px;
  margin-right: 4px;
  background-color: transparent;
  transition: background-color 0.5s ease;
}

.last-circle {
  margin-right: 0; /* Remove margin-right for the last circle */
}

.filled {
  background-color: white;
}

@media (max-width: 530px) {
  .rounded-top,
  .circle-container,
  .bottom-div {
    display: none;
  }
}

.updated-text {
  margin-top: -4px;
  margin-bottom: 4px;
  margin-left: auto;
  margin-right: 10px;
  font-size: 12px;
}
</style>
